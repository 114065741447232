import React, { useEffect } from 'react';
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from "react-bootstrap/Image";
import { Link, useLocation } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Footer from '../components/Footer';
import Badge from 'react-bootstrap/Badge';
import Metadata from '../components/Metadata';
import Card from 'react-bootstrap/Card';
import Youtube from '../components/Youtube';
import Navigation from '../components/Navigation';
import '../App.css';

function LandingPage() {
    const [exampleImage, setExampleImage] = useState('img/female_model1/');
    const location = useLocation();
    const queryString = new URLSearchParams(location.search);
    const queryParam = queryString.get('v');
    const [variant, setVariant] = useState(queryParam ? queryParam : 'primary');
    
    useEffect(() => {
        if(variant === 'cat' || variant === 'dog' || variant === 'male')
            setExampleImage('img/' + variant + '_model1/');
    }, [variant])

    return (        
        <Container fluid className="gx-0 banner-bg pt-3">
            <Row>
                <Container>
                    <Navigation />
                </Container>
            </Row>
            <Metadata title="Embark on Epic Quests with AI-Powered Storytelling." link="https://www.infinityfable.ai" description="Welcome to InfinityFable, the revolutionary platform that uses artificial intelligence to generate immersive Dungeons & Dragons stories and characters. Dive into a world of endless adventures with friends, as our interactive and multiplayer system creates uniquely tailored experiences for your party. Unleash your imagination and let InfinityFable guide you on unforgettable journeys!" />
            <Row>
                <Col>
                <Container fluid className="gx-0">
                    <Row>
                        <Col>
                            <Container className="gx-0">
                                <Row className="py-5">
                                    <Col md="6" className="d-flex align-items-center">
                                        <div>
                                            <div className="mb-4">
                                                <h1 className='mb-3'>Embark on Epic Quests<br/> with AI-Powered Dungeons.</h1>
                                                <p>Welcome to CriticalMiss, the revolutionary platform that uses artificial intelligence to generate immersive Dungeons & Dragons stories and characters. Dive into a world of endless adventures with friends, as our interactive and multiplayer system creates uniquely tailored experiences for your party. Unleash your imagination and let CriticalMiss guide you on unforgettable journeys!</p>
                                            </div>
                                            <div className="mb-3">
                                                <Button as={Link} to="/sign-up" variant="danger" className="cta shadow btn-xxl ">Get Started</Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="cta-bg">
                    <Row className="justify-content-md-center text-center p-5">
                        <Col md="12">
                        </Col>
                    </Row>
                </Container>
                
                <Container fluid className="gx-0 perspective">
                    <Row><Col>
                    <Container fluid className='gx-0 section-1'>
                            <Row><Col>
                                <Container className="gx-0 py-5">
                                    <Row className="justify-content-md-center">
                                        <Col md="12">
                                            <div className="text-center">
                                                <div>Gameplay</div>
                                                <h3>Unleash Your Inner Hero</h3>
                                            </div>
                                            <p>Embark on your journey with a gameplay structure deeply inspired by the mechanics of Dungeons & Dragons. Our game embraces this tried-and-true system, offering a familiar framework for seasoned adventurers while being accessible to newcomers. From character creation and development to combat encounters and skill checks, the mechanics are designed to be intuitive and engaging. Coupled with our AI's unique capability to adapt and generate content, these mechanics ensure that each playthrough offers a fresh, D&D-like experience in a world that's entirely your own. So gear up, step into the fray, and let your actions shape the narrative of your adventure.</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <Container fluid className="gx-0">
                                                <Row>
                                                    <Col md="12">
                                                        <h3>Generative Dungeon Master</h3>
                                                        <p>Embark on a journey with our state-of-the-art AI Dungeon Master (DM) at the helm. The DM, powered by cutting-edge AI technology, dynamically manages each player's story arc. Reacting to your actions, the AI crafts personalized narratives that ensure no two games are alike. It expertly balances story progression, combat, and player choices, providing a unique and captivating adventure with every playthrough. Dive into a world that evolves with you.</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <h3>Combat Management</h3>
                                                        <p>Venture into the vast expanse of our world on your own or unite with other players. You can navigate the rich lore at your own pace, taking individual journeys that let you explore to your heart's content. Or you can band together with friends and fellow adventurers, combining your strengths to overcome greater challenges in a shared journey. Our game fully supports and equally enriches both solo and group playthroughs, allowing you to enjoy the adventure your way.</p>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                        <Col>
                                            <Image src="img/traveler.png" fluid />
                                        </Col>
                                    </Row>
                                </Container>
                            </Col></Row>
                    </Container>
                    </Col></Row>
                </Container>

                <Container fluid className="gx-0 perspective">
                    <Row><Col>
                    <Container fluid className='gx-0 section-2'>
                        <Row><Col>
                        <Container className="gx-0 py-5">
                            <Row className="justify-content-md-center">
                                <Col md="12">
                                    <div className="text-center">
                                        <div>World</div>
                                        <h3>Forge Your Own Epic Adventure</h3>
                                    </div>
                                    <p>Immerse yourself in a world where your decisions shape not only the outcome of the story but also the very fabric of the game universe itself. With limitless possibilities, the generative storytelling engine adapts to your gameplay style, ensuring that no two experiences are ever alike, creating a truly personalized journey for every player. Prepare to embark on an unforgettable adventure where the boundaries between reality and fiction blur, and your role as the protagonist becomes an extraordinary narrative journey.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Image src="img/map-overlay.png" fluid />
                                </Col>
                                <Col md="4">
                                    <Container fluid className="gx-0">
                                        <Row>
                                            <Col md="12">
                                                <h3>Unbounded Storytelling</h3>
                                                <p>The only boundary in our game is that the lore comes from D&D 5e. Beyond that, your narrative is yours to shape. Whether you wish to be a hero, a villain, a mercenary, an explorer, or something entirely different, you have the freedom to carve out your own story. The AI DM adapts the narrative around your choices, ensuring a one-of-a-kind narrative experience every time. Embrace your role and let your decisions shape the world.</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <h3>Theme Customization</h3>
                                                <p>That not all - prepare for a twist unlike anything you've experienced before. With custom theme injection, you can inject your imagination into the game world, transforming ordinary locations into extraordinary realms infused with your wildest ideas. Turn every village into a vibrant BBQ sauce haven or unleash chaos by making the dungeons twist and turn like a smoky barbecue feast. The possibilities are limitless as you redefine the boundaries of D&D gameplay and create a truly unique and flavorful experience.</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                        </Col></Row>
                    </Container>
                    </Col></Row>
                </Container>

                <Container fluid className="gx-0 footer-bg">
                    <Row><Col>
                    <Container className="gx-0">
                            <Row className="justify-content-md-center text-center p-5 pb-0">
                                <Col md="12">
                                    <h1 className='mb-3' id="pricing">Pricing</h1>
                                        <p>Embark on thrilling adventures and explore the vast realms of your imagination with our AI-generated campaigns and characters. Delve into the unknown, forge legendary tales, and unite with fellow adventurers from across the globe. Choose your destiny and become a part of the epic story that awaits!</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center text-center p-5 pt-0">
                                <Col md="4" className="mb-3">
                                    <Card className="shadow-lg">
                                        <Card.Header><h4 className='my-0'>Single Campaign</h4></Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <h4>Free</h4>
                                                <p>Create unique AI-generated campaigns from over 30 themes to choose from, including the ability to customize your own.</p>
                                                <ul className='list-unstyled'>
                                                    <li>Up to 1 Campaigns</li>
                                                    <li>5 Pre-prompted Themes</li>
                                                    <li>AI-Generated Characters &  Scenes</li>
                                                    <li>+10 AI-Generated Characters</li>
                                                    <li>+500 Interactions</li>
                                                </ul>
                                            </Card.Text>
                                            <Button as={Link} to="/sign-up">Get started</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md="4" className="mb-3">
                                    <Card className="shadow-lg">
                                        <Card.Header><h4 className='my-0'>Campaign Boosts</h4></Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <h4>$5 per boost</h4>
                                                <p>Create unique AI-generated campaigns from over 30 themes to choose from, including the ability to customize your own.</p>
                                                <ul className='list-unstyled'>
                                                    <li>Any time during a campaign</li>
                                                    <li>Unlock 30 Pre-prompted Themes</li>
                                                    <li>User-based Seeding Available</li>
                                                    <li>+10 AI-Generated Characters & Scenes</li>
                                                    <li>+1000 Interactions</li>
                                                </ul>
                                            </Card.Text>
                                            <Button as={Link} to="/sign-up">Get started</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <Card>
                                            <Card.Header><h4 className='my-0'>More Campaigns <Badge bg="secondary">Addon</Badge></h4></Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <h4>$3</h4>
                                                <p>For every additional campaign</p>
                                                <ul className='list-unstyled'>
                                                    <li><strong>After your 1st campaign*</strong></li>   
                                                </ul>
                                            </Card.Text>
                                            <Button as={Link} variant="outline-secondary" to="/sign-up">Get Started</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row> 
                        </Container>
                    </Col>
                    </Row>
                    <Row className="doggos-bg">
                        <Col>
                            <Container>
                                <Row>
                                        <Col className="d-flex align-items-center flex-column">
                                            <h3>Start your journey today.</h3>
                                            <Button as={Link} to="/sign-up" variant="danger" className="cta shadow btn-xxl ">Get Started</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}

export default LandingPage;