import Authentication from '../components/Authentication'
import Metadata from '../components/Metadata'

const ForgotPassword = () => {
  return (
    <>
      <Metadata title="Forgot Password" link="https://www.electron.ai/forgot-password" description="Put yourself into any photo, in any situation or style you can imagine. Just like magic, you'll be able to jump into the scene and experience it like you're really there. Get ready for some seriously cool selfies! Sign up today!" />
      <Authentication type="forgot-password"/>
    </>
  )
}

export default ForgotPassword