export const updateNotification = (message, status) => {
  return async (dispatch) => {
      dispatch({
        type: "UPDATE_NOTIFICATION",
        payload: { message, status },
      });
  };
}

export const clearNotification = () => {
  return async (dispatch) => {
      dispatch({
        type: "UPDATE_NOTIFICATION",
        payload: {},
      });
  };
}