const CREATE_CHARACTER = 'CREATE_CHARACTER';
const READ_CHARACTERS = 'READ_CHARACTERS';
const READ_SINGLE_CHARACTER = 'READ_SINGLE_CHARACTER';
const SET_CHARACTER_TYPE = 'SET_CHARACTER_TYPE';
const READ_USERNAME = 'READ_USERNAME';
const UPDATE_CHARACTER = 'UPDATE_CHARACTER';
const DELETE_CHARACTER = 'DELETE_CHARACTER';

// Define the initial state of the store
const initialState = {
  characters: [],
  character: {},
  progress: 0,
  prompt: {},
  usernameExists: {},
  type: 'player',
};

export const charactersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHARACTER_TYPE: {
      return {
        ...state,
        type: [...state.payload, action.payload],
      };
    }
    case CREATE_CHARACTER: {
      return {
        ...state,
        character: action.payload,
      };
    }

    case READ_USERNAME: {
      return {
        ...state,
        usernameExists: action.payload,
      };
    }

    case READ_SINGLE_CHARACTER: {
      return {
        ...state,
        character: action.payload,
      };
    }
    case READ_CHARACTERS: {
      return {
        ...state,
        characters: action.payload,
      };
    }
    case UPDATE_CHARACTER: {
      const { id, updates } = action.payload;
      const updatedCharacters = state.characters.map((character) => {
        if (character.id === id) {
          return { ...character, ...updates };
        }
        return character;
      });
      return {
        ...state,
        characters: updatedCharacters,
      };
    }
    case DELETE_CHARACTER: {
      const id = action.payload;
      const remainingCharacters = state.characters.filter(
        (character) => character._id !== id
      );
      return {
        ...state,
        characters: remainingCharacters,
      };
    }
    default: {
      return state;
    }
  }
}