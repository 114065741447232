import axios from 'axios';
import { updateNotification } from "./notifications";

export const setCampaignType = (type) => {
  return {
    type: "SET_CAMPAIGN_TYPE",
    payload: type
  }
}

export const startCampaign = (hash) => {
  const token = sessionStorage.getItem('token') || "";
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/campaign/${hash}/start`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      dispatch({
        type: "START_CAMPAIGN",
        payload: response.data,
      });
    } catch (error) {
      if (error.response.data.message) {
        const notificationAction = updateNotification(`${error.response.data.message}`, true);
        dispatch(notificationAction);
      } else {
        const notificationAction = updateNotification(`${error.message}`, true);
        dispatch(notificationAction);
      }
    }
  };
}

export const removeCharacter = (payload) => {
  const { campaignId, characterId } = payload;
  const token = sessionStorage.getItem('token') || "";
  console.log(sessionStorage.token)
  const headers = {
    "Authorization": `Bearer ${token}`,
  };

  return async (dispatch) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/campaign/character`, { 
        data: { campaignId: campaignId, characterId: characterId }, 
        headers 
      });

      dispatch({
        type: "REMOVE_CHARACTER_FROM_CAMPAIGN",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      if (error.response.data.message) {
        const notificationAction = updateNotification(`${error.response.data.message}`, true);
        dispatch(notificationAction);
      } else {
        const notificationAction = updateNotification(`${error.message}`, true);
        dispatch(notificationAction);
      }
    }
  };
}


export const addCharacter = (payload) => {
  const { campaignId, characterId } = payload;
  const token = sessionStorage.getItem('token') || "";
  const headers = {
    "Authorization": `Bearer ${token}`,
  };

  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/campaign/character`, { campaignId: campaignId, characterId: characterId
      }, { headers });

      dispatch({
        type: "ADD_CHARACTER_TO_CAMPAIGN",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
      if(error.response.data.message) {
        const notificationAction = updateNotification(`${error.response.data.message}`, true);
        dispatch(notificationAction);
      } else {
        const notificationAction = updateNotification(`${error.message}`, true);
        dispatch(notificationAction);
      }
    }
  };
}

export const createCampaign = (payload) => {

  const { name, classes, world, description } = payload;
  console.log(`createCampaign Payload`,payload);

  const token = sessionStorage.getItem('token') || "";
  const headers = {
    "Authorization": `Bearer ${token}`,
  };
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/campaign`, {
        "name": name,
        "classes": classes,
        "world": world,
        "description": description,
      }, { headers });

      dispatch({
        type: "CREATE_CAMPAIGN",
        payload: response.data,
      });

      updateNotification(`Campaign "${name}" created successfully.`, true);

    } catch (error) {
      // Handle error
      const notificationAction = updateNotification(`${error.message}`, true);
      dispatch(notificationAction);
    }
  };
}

export const readCampaigns = (campaigns) => {
  const token = sessionStorage.getItem('token') || "";
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/campaign`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      dispatch({
        type: "READ_CAMPAIGNS",
        payload: response.data,
      });
      
    } catch (error) {
      // Handle error
    }
  };
}

export const updateCampaign = (id, updates) => {
  const token = sessionStorage.getItem('token') || "";
  return async (dispatch) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/campaign/${id}`,updates, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      dispatch({
        type: "UPDATE_CAMPAIGN",
        payload: response.data,
      });

      dispatch(readCampaigns())

    } catch (error) {
      // Handle error
    }
  };
}

export const readSingleCampaign = (id) => {
  const token = sessionStorage.getItem('token') || "";
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/campaign/${id}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      dispatch({
        type: "READ_SINGLE_CAMPAIGN",
        payload: response.data,
      });
    } catch (error) {
      // Handle error
    }
  };
}

export const deleteCampaign = (id, name) => {
  const token = sessionStorage.getItem('token') || "";
  return async (dispatch) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/campaign/${id}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      dispatch({
        type: "DELETE_CAMPAIGN",
        payload: id,
      });

      const notificationAction = updateNotification(`Project "${name}" deleted.`, true);
      dispatch(notificationAction);
    } catch (error) {
      // Handle error
    }
  };
}

export const enhanceCampaign = (hash, id, image_id) => {
  const token = sessionStorage.getItem('token') || "";
  return async (dispatch) => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/campaign/${hash}/prompts/${id}/images/${image_id}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      dispatch({
        type: "ENHANCE_CAMPAIGN",
        payload: id,
      });

      const notificationAction = updateNotification(`Enhance request started. Please come back in 5 minutes to see your enhanced results.`, true);
      dispatch(notificationAction);
    } catch (error) {
      const notificationAction = updateNotification(error.response.data.msg, true);
      dispatch(notificationAction);
      // Handle error
    }
  };
}
 

export const clearCampaigns = () => {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_CAMPAIGNS",
      payload: {},
    });
  }
}