import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'
import Dropdown from 'react-bootstrap/Dropdown'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import EmptyState from '../../components/EmptyState'

import { useSelector, useDispatch } from "react-redux";
import { readCampaigns, updateCampaign, deleteCampaign } from "../../redux/actions/campaigns";
import { updateNotification } from "../../redux/actions/notifications";
import { useAuthState } from '../../providers/Firebase';

import * as Icon from 'react-bootstrap-icons';
import { ListGroup } from 'react-bootstrap'
import Metadata from '../../components/Metadata'
import Navigation from '../../components/Navigation'

const CampaignsList = () => {
  const { isAuthenticated, metadata } = useAuthState()
  const campaigns = useSelector((state) => state.campaigns.campaigns);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleReadCampaigns = () => {
    const action = readCampaigns();
    dispatch(action);
  };

  const handleUpdateNotification = (message, show) => {
    const action = updateNotification(message, show);
    dispatch(action);
  };

  const handleupdateCampaign = (id, name, updates) => {
    const action = updateCampaign(id, updates);
    if(updates.status === 'processing') {
      handleUpdateNotification(`Project "${name}" is processing.`, true)
    }
    dispatch(action);
  };

  const handledeleteCampaign = (id, name) => {
    let prompt = window.prompt(`Type "DELETE" to confirm campaign deletion.`)
    if(prompt === "DELETE") {
      // Delete it!
      const action = deleteCampaign(id, name);
      dispatch(action);
    } else {
      // Do nothing!
    }
  };

  useEffect(()=>{
    handleReadCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch, isAuthenticated])

  useEffect(()=>{
    handleReadCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const Campaign = (props) => {
    const { campaign } = props;
        
    const HandleResubmit = (props) => {
      const { campaign } = props;
      if(campaign.status === 'processed' || campaign.status === 'finished' || campaign.status === 'processing' || campaign.status === 'complete') {
        return (<></>)
      } else {
        return (
          <><Dropdown.Item onClick={() => handleupdateCampaign(campaign._id, campaign.name, {status: 'processing', upload: "true"})} size="sm">Process</Dropdown.Item></>
        )
      }
      
    }

    return (
    <>
      <Row>
        <Col>
          <ListGroup className="shadow mb-5">
            <ListGroup.Item className="list-group-item-secondary d-flex justify-content-between align-items-start">
              <div className="me-auto text-truncate margin-right">{campaign.name}<br/><Badge pill bg='secondary'>Class: {campaign.classes}</Badge>{' '}</div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-basic" className="dropdown-reset">
                    <Icon.ThreeDots />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <HandleResubmit campaign={campaign} />
                    <Dropdown.Item onClick={() => handledeleteCampaign(campaign._id, campaign.name)} >Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </ListGroup.Item>
          {campaign?.files?.length > 0 ? (<ListGroup.Item style={{"overflow":"hidden", "padding":"0px", 'height': '225px'}}><Image fluid src={`https://storage.cloud.google.com/electron-ai/${campaign.hash}/${campaign?.files[0]}`}/>
          { campaign.status === 'complete' ? (<div className="gradient p-3"><Button className="great-success" as={Link} to={`/campaigns/${campaign.hash}`} variant="success">See Results</Button></div>) : (<></>) }</ListGroup.Item>) : (<div></div>)}
          </ListGroup>
        </Col>
      </Row>
    </>
    );
  }
  
  return (<>
    <Container className="gx-0 pt-3">
      <Row><Container><Navigation /></Container></Row>
      <Metadata title="Campaigns" link="https://www.electron.ai/campaigns" description="Put yourself into any photo, in any situation or style you can imagine. Just like magic, you'll be able to jump into the scene and experience it like you're really there. Get ready for some seriously cool selfies! Sign up today!" />
      <Row className="py-3">
        <Col>
          <Row className="pb-2 justify-content-between align-items-center">
            <Col>
              <h5 className="my-0">Your Campaigns</h5>
            </Col>
            {campaigns?.length > 0 && (<Col style={{ textAlign: 'right' }}><Button onClick={() => navigate('/campaigns/create')}>Create a Campaign</Button></Col>)}
          </Row>
          <Row className="mb-4">
            <Col>
              {campaigns?.length > 0 ? (
                <Table variant="dark" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Participants</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns.map((campaign, index) => (
                      <tr key={index}>
                        <td><Link to={`/campaigns/${campaign._id}`}>{campaign.name}</Link></td>
                        <td>{campaign.participants.map(participant => participant.name).join(', ')}</td>
                        <td><Button variant="primary" size="sm"><i className="bi bi-three-dots-vertical"></i></Button></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (<EmptyState
                title="Get started with your first campaign"
                subtitle="You can get started right away, but to submit the project for processing, you'll have to buy a project credit. To do this, please follow the link above."
                link="/campaigns/create"
                linkText="Create a Campaign" />)}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    </>)
  }

export default CampaignsList