import React, { useEffect, useState } from 'react'
import { Toast } from 'react-bootstrap'

import { useSelector, useDispatch } from "react-redux";

const ToastNotification = (props) => {
    const notification = useSelector((state) => state.notifications.notification);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setShowToastMessage] = useState("");
    const dispatch = useDispatch()

    useEffect(() => {
        if(notification?.status === false || notification?.status === undefined) {
        } else {
            setShowToast(notification.status)
            setShowToastMessage(notification.message)
            dispatch({ type: 'CLEAR_NOTIFICATION' })
        }
    }, [dispatch, notification]);

    const onClose = () => {
        setShowToast(false)
        setShowToastMessage("")
        dispatch({ type: 'CLEAR_NOTIFICATION' })
    }

    return (
        <Toast show={showToast} onClose={()=>onClose()} className="position-relative" animation delay={3000} autohide>
            <Toast.Header>
                <strong className="mx-auto">Notification</strong>
            </Toast.Header>
            <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
    );
}

export default ToastNotification;