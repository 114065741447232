import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import { useState, useEffect, useContext, createContext } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { readSingleUser } from "../redux/actions/users";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp);

export const AuthContext = createContext()

export const AuthContextProvider = props => {
  const [user, setUser] = useState()
  const [error, setError] = useState()
  const metadata = useSelector((state) => state.users.user);
  const dispatch = useDispatch()

  const handleSingleUser = () => {
    const action = readSingleUser();
    dispatch(action);
  };

  useEffect(() => {
    console.log('AuthContextProvider useEffect')
    handleSingleUser()
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
    return () => unsubscribe()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <AuthContext.Provider value={{ user, metadata, error }} {...props} />
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)

  return { ...auth, isAuthenticated: auth.user != null }
}