import axios from 'axios';

export const readSingleUser = () => {
  const token = sessionStorage.getItem('token') || "";
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/me`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      dispatch({
        type: "READ_SINGLE_USER",
        payload: response.data.user,
      });
    } catch (error) {
      // Handle error
    }
  };
}

export const logOut = () => {
  return (dispatch) => {
      dispatch({
        type: "LOGOUT",
        payload: {},
      });
  };
}