import { useCallback, useEffect } from 'react'
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, setPersistence, browserSessionPersistence, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { useNavigate, Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import GoogleButton from 'react-google-button'
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import axios from 'axios'
import { useAuthState } from '../providers/Firebase';
import { useDispatch, useSelector } from "react-redux";
import { readSingleUser } from "../redux/actions/users";
import { readCampaigns } from "../redux/actions/campaigns";
import { updateNotification } from "../redux/actions/notifications";

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  'login_hint': 'user@example.com'
});

const fbProvider = new FacebookAuthProvider();

function SocialSignIn(props) {
  const { handleSignInWithGoogle, handleSignInWithFacebook } = props.fns;
  return (
    <Row>
      <Col md={{ span: 6, offset: 3 }}  >
      <div className='text-center '>
        <p className="p-3">or connect with</p>
        <div className="login-buttons text-center">
        <GoogleLoginButton variant="primary" onClick = {handleSignInWithGoogle} className="m-auto mb-2" />
        <FacebookLoginButton variant="primary" onClick = {handleSignInWithFacebook} className="m-auto" />
        </div>
      </div>
      </Col>
    </Row>
    
  )
}

function ForgotPassword(props) {
  const { handlePasswordReset } = props.fns;
  return (
    <Container fluid className="vh-custom">
        <Row className="d-flex vh-custom">  
          <Col md={{ span: 4, offset: 2 }} className="align-self-center">
          <h3 className="text-center">Reset Your Password</h3>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
              <Form onSubmit={handlePasswordReset}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" name='email' placeholder="Enter email" />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
              </Col>
            </Row>
          </Col> 
          <Col md="6" className="login-bg align-self-right">
            &nbsp;
          </Col>
        </Row>
    </Container>
  )
}


function SignIn(props) {
  const { isAuthenticated, handleSingleUser, handleReadCampaigns, handleUpdateNotification, handleSignInWithGoogle, handleSignInWithFacebook, navigate } = props.fns;

  useEffect(() => {
    if(isAuthenticated) {
      navigate('/campaigns')
    }
  }, [isAuthenticated])

  const handleSignInWithEmailPassword = useCallback(async e => {
    e.preventDefault()
    const auth = getAuth()
    const { email, password } = e.target.elements
    console.log(`logging in`,email.value, password.value)
    try {
      const result = await signInWithEmailAndPassword(auth, email.value, password.value)
      console.log(`result`,result)
      const user = result.user;

      // Set tokens and user in sessionStorage
      sessionStorage.setItem("user", JSON.stringify(user))
        
      // Validate the token with the API
      const authResponse = await axios.post(`${process.env.REACT_APP_API_URL}/auth/validate`, {
        "idToken": user.accessToken
      });

      // console.log(authResponse.data)
      sessionStorage.setItem("token", authResponse.data.token)

      handleSingleUser()
      handleReadCampaigns()
      // navigate to campaigns page
      navigate('/campaigns')

    } catch (e) {
      if(e.message === "Firebase: Error (auth/user-not-found)." || e.message === "Firebase: Error (auth/wrong-password).") {
        handleUpdateNotification("Invalid Email or Password. Please try again.", true);
        return
      }
      handleUpdateNotification("Something went wrong. Please try again.", true);
      return
    }
  })

  return (
    <Container fluid className="vh-custom">
        <Row className="d-flex vh-custom">  
          <Col md={{ span: 4, offset: 4 }} className="align-self-center">
          <h3 className="text-center">Sign In</h3>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
              <Form onSubmit={handleSignInWithEmailPassword}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" name='email' placeholder="Enter email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" name='password' placeholder="Password" />
                </Form.Group>
                <Row>
                  <Col md="12">
                    <Button className="float-start" variant="primary" type="submit">Submit</Button><span className="mt-2 float-end text-muted align-middle"><Link to="/forgot-password">Forgot Password?</Link></span>
                </Col>
                </Row>
                
              </Form>
              </Col>
            </Row>
            {/* <SocialSignIn fns={{handleSignInWithGoogle, handleSignInWithFacebook}} /> */}
            <Row className="mt-5"><Col>
              <p className="text-muted text-center">Don't have an account? <Link to="/sign-up">Sign Up</Link></p>
            </Col></Row>
          </Col> 
        </Row>
    </Container>
  );
}

function SignUp(props) {
  const { handleSingleUser, handleUpdateNotification, handleSignInWithGoogle, handleSignInWithFacebook, navigate } = props.fns;

  const handleSubmit = useCallback(async e => {
    e.preventDefault()

    const { email, password } = e.target.elements
    const auth = getAuth()
    try {
      const result = await createUserWithEmailAndPassword(auth, email.value, password.value)
      
      const user = result.user;

      // Set tokens and user in sessionStorage
      sessionStorage.setItem("user", JSON.stringify(user))
      
      // Validate the token with the API
      const authResponse = await axios.post(`${process.env.REACT_APP_API_URL}/auth/validate`, {
        "idToken": user.accessToken
      });

      sessionStorage.setItem("token", authResponse.data.token)

      handleSingleUser()
      navigate('/characters')

    } catch (e) {
      handleUpdateNotification(e.message, true)
    }
  }, [])

  return (
    <Container fluid className="vh-custom">
        <Row className="d-flex vh-custom">  
          <Col md={{ span: 4, offset: 4 }} className="align-self-center">
          <h3 className="text-center">Sign Up</h3>
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" name='email' placeholder="Enter email" />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" name='password' placeholder="Password" />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
              </Col>
            </Row>
            {/* <SocialSignIn fns={{handleSignInWithGoogle, handleSignInWithFacebook}} /> */}
            <Row className="mt-5"><Col>
              <p className="text-muted text-center">Already have an account? <Link to="/login">Sign In</Link></p>
            </Col></Row>
          </Col> 
        </Row>
    </Container>
  );
}

function Authentication(props) {
    const authState = useAuthState()
    const { isAuthenticated } = authState
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const campaigns = useSelector((state) => state.campaigns.campaigns);

    const handleSingleUser = () => {
        const action = readSingleUser();
        dispatch(action);
    };

    const handleReadCampaigns = () => {
        const action = readCampaigns();
        dispatch(action);
    };

    const handleUpdateNotification = (message, status) => {
        const action = updateNotification(message, status);
        dispatch(action);
    }
    
    const handlePasswordReset = useCallback(async e => {
        e.preventDefault()
    
        const { email } = e.target.elements
        const auth = getAuth()
        try {
          await sendPasswordResetEmail(auth, email.value)
          handleUpdateNotification('Password reset email sent.', true)
          navigate('/login')
        } catch (e) {
          handleUpdateNotification(e.message, true)
        }
    }, [])

    const handleSignInWithGoogle = useCallback(async e => {
        e.preventDefault()
    
        const auth = getAuth()
        try {
          await setPersistence(auth, browserSessionPersistence)
          await signInWithPopup(auth, provider).then(async (result) => {
            // The signed-in user info.
            const user = result.user;
            console.log(user)
            // Set tokens and user in sessionStorage
            sessionStorage.setItem("user", JSON.stringify(user))
            
            // Validate the token with the API
            const authResponse = await axios.post(`${process.env.REACT_APP_API_URL}/auth/validate`, {
              "idToken": user.accessToken
            });
    
            console.log(authResponse)
            sessionStorage.setItem("token", authResponse.data.token)
            
            handleSingleUser()
            handleReadCampaigns()
            // navigate to campaigns page
            navigate('/characters')
    
            // ...
          }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log(errorCode, errorMessage, credential)
            // ...
          });
        } catch (e) {
          handleUpdateNotification(e.message, true);
          return
        }
      }, [navigate])

    const handleSignInWithFacebook = useCallback(async e => {
      e.preventDefault()
  
      const auth = getAuth()
      try {
        await setPersistence(auth, browserSessionPersistence)
        await signInWithPopup(auth, fbProvider).then(async (result) => {
          // The signed-in user info.
          const user = result.user;
          console.log(user)
          // Set tokens and user in sessionStorage
          sessionStorage.setItem("user", JSON.stringify(user))
          
          // Validate the token with the API
          const authResponse = await axios.post(`${process.env.REACT_APP_API_URL}/auth/validate`, {
            "idToken": user.accessToken
          });
  
          sessionStorage.setItem("token", authResponse.data.token)
          
          handleSingleUser()
          handleReadCampaigns()
          // navigate to campaigns page
          navigate('/campaigns')
  
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          
          if(errorMessage.includes("auth/account-exists-with-different-credential")) {
            handleUpdateNotification("An account is aready registered using this email.", true);
            return
          }

          // The AuthCredential type that was used.
          const credential = FacebookAuthProvider.credentialFromError(error);

          // ...
        });
      } catch (e) {
        handleUpdateNotification(e.message, true);
        return
      }
    }, [navigate])

    const { type } = props;

    if(type === 'sign-up') 
      return (<SignUp fns= {{...authState, isAuthenticated, dispatch, campaigns, handleSingleUser,  handleReadCampaigns, handleUpdateNotification, handleSignInWithGoogle, handleSignInWithFacebook, navigate}}  />)
    
    if(type === 'sign-in') 
      return (<SignIn fns= {{...authState, isAuthenticated, dispatch, campaigns, handleSingleUser,  handleReadCampaigns, handleUpdateNotification, handleSignInWithGoogle, handleSignInWithFacebook, navigate}}  />)
    
    if(type === 'forgot-password') 
      return (<ForgotPassword fns= {{...authState, isAuthenticated, dispatch, campaigns, handleSingleUser,  handleReadCampaigns, handleUpdateNotification, handleSignInWithGoogle, handleSignInWithFacebook, navigate, handlePasswordReset }}  />)


}

export default Authentication;