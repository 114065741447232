const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

// Define the initial state of the store
const initialState = {
    notification: {}
};

export const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_NOTIFICATION: {
        return {
          ...state,
          notification: action.payload,
        };
      }
      case CLEAR_NOTIFICATION: {
        return {
          ...state,
          notification: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  }