import React from 'react';
import {Helmet} from "react-helmet";

function Metadata(props) {
    const { title, link, description } = props
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>CriticalMiss - {title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={{ link }} />
        </Helmet>
    )
}

export default Metadata;