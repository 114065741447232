const READ_SINGLE_MESSAGE = 'READ_SINGLE_MESSAGE';
const READ_MESSAGES = 'READ_MESSAGES';
const CREATE_MESSAGE = 'CREATE_MESSAGE';

// Define the initial state of the store
const initialState = {
  message: {},
  messages: []
};

export const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_SINGLE_MESSAGE: {
      return {
        ...state,
        message: action.payload,
      };
    }
    case READ_MESSAGES: {
      return {
        ...state,
        messages: action.payload,
      };
    }
    case CREATE_MESSAGE: {
      return {
        ...state,
        messages: [...state.messages, ...action.payload],
      };
    }
    default: {
      return state;
    }
  }
}