import React, { Suspense } from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route,
  Navigate
} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Container from 'react-bootstrap/Container';
import LandingPage from './pages/LandingPage';

import ForgotPassword from './pages/ForgotPassword';
import Payments from './pages/Payments/Choose';

import 'bootstrap/dist/css/bootstrap.min.css';
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import Navigation from './components/Navigation';
import { useAuthState, AuthContextProvider } from './providers/Firebase';
import ToastNotification from './components/ToastNotification'
import ToastContainer from 'react-bootstrap/ToastContainer'

// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

const CampaignsList = React.lazy(() => import('./pages/Campaigns/List'));
const CampaignDetails = React.lazy(() => import('./pages/Campaigns/Details'));
const CampaignsCreate = React.lazy(() => import('./pages/Campaigns/Create'));

const CharacterCreate = React.lazy(() => import('./pages/Characters/Create'));
const CharactersList = React.lazy(() => import('./pages/Characters/List'));

const ToS = React.lazy(() => import('./pages/ToS'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const Login = React.lazy(() => import('./pages/Login'));
const SignUp = React.lazy(() => import('./pages/SignUp'));

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuthState()
  const loggedIn = isAuthenticated;

  if(!loggedIn) return <Navigate to="/login" />
  return children
}

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <AuthContextProvider>
        <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
        <Router>
          <ToastContainer className="p-3" position={'top-center'}>
            <ToastNotification />
          </ToastContainer>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route exact path="/dashboard" element={ 
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute> } />
                <Route path="/characters">
                    <Route exact path="/characters" element={
                      <PrivateRoute>
                        <CharactersList />
                      </PrivateRoute>} />
                    <Route exact path="/characters/create" element={
                      <PrivateRoute>
                        <CharacterCreate />
                      </PrivateRoute>} />
                </Route>
                <Route path="/campaigns">
                  <Route exact path="/campaigns" element={ 
                    <PrivateRoute>
                        <CampaignsList />
                    </PrivateRoute> } />
                  <Route exact path="/campaigns/create" element={ 
                  <PrivateRoute>
                    <CampaignsCreate />
                  </PrivateRoute> } />
                      <Route exact path="/campaigns/:campaignHash" element={<CampaignDetails />} />
                </Route>
                <Route exact path="/payments" element={ 
                <PrivateRoute>
                  <Payments />
                </PrivateRoute> } />
                
                <Route exact path="/ToS" element={<ToS/>} />
                <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
            </Routes>  
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route exact path="/" element={ <LandingPage /> } />
            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/sign-up" element={<SignUp/>} />
            <Route exact path="/forgot-password" element={<ForgotPassword/>} />
          </Routes>
          </Suspense>
        </Router>
        </ThemeProvider>
      </AuthContextProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
