import React from 'react';
import { Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import '../App.css';

function Footer() {
    return (
        <Container fluid className="px-0">
            
        </Container>
    )
}

export default Footer;