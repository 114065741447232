import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { Link }  from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth'
import { useAuthState } from '../providers/Firebase';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { logOut } from '../redux/actions/users';
import { clearCampaigns } from '../redux/actions/campaigns';

function SignedIn(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user, metadata } = props
  function logout() {
    sessionStorage.removeItem("user")
    sessionStorage.removeItem("token")
    dispatch(logOut())
    dispatch(clearCampaigns())
    navigate('/login')
    signOut(getAuth())
  }

  return (<><Nav>
    <Navbar.Text>Welcome,</Navbar.Text>
    <NavDropdown title={user?.email} id="basic-nav-dropdown">
    <NavDropdown.Item onClick={()=> logout() }>
      Sign out
    </NavDropdown.Item>
  </NavDropdown></Nav></>)
}

function SignedOut() {
  return (<Nav>
    <Nav.Link variant="outline-primary" href="/#pricing">
    Pricing
    </Nav.Link>
    <Nav.Link variant="outline-primary" as={Link} className="mx-2" to="/login">
    Sign in
    </Nav.Link>
    <Button variant="danger" as={Link} to="/sign-up">
    Sign up
    </Button >
  </Nav>)
}


function Widget(props) {
  const { isAuthenticated, user, metadata } = props
  return(isAuthenticated ? <SignedIn user={user} metadata={metadata} /> : 
  <>
    {<SignedOut/>}
  </>)
}

function Navigation(props) {
    const { fullsize } = props
    const authState = useAuthState()
    const { isAuthenticated } = authState
    const metadata = useSelector((state) => state.users.user);

    return (
        <Container className="gx-0" fluid={fullsize}>
          <Navbar expand="lg" className="navbar">
            <Container>
            <Navbar.Brand as={Link} to="/" className="d-flex align-items-center"> 
              <span><img src="/img/logo.png" alt="" height="35" class="d-inline-block align-text-top"/></span>
              <span className="mt-1 mx-2">CriticalMiss</span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  
                  {
                    // if else logic in react jsx
                    isAuthenticated ? (<>
                      <Nav.Link as={Link} to="/campaigns">Campaigns</Nav.Link>
                      <Nav.Link as={Link} to="/characters">Characters</Nav.Link>
                      </>) : <></>
                  }
                </Nav>
                <Widget {...authState} metadata={metadata} />
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>
      
    );
  }
  
  export default Navigation;