const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
const READ_CAMPAIGNS = 'READ_CAMPAIGNS';
const READ_SINGLE_CAMPAIGN = 'READ_SINGLE_CAMPAIGN';
const SET_CAMPAIGN_TYPE = 'SET_CAMPAIGN_TYPE';
const ADD_CHARACTER_TO_CAMPAIGN = 'ADD_CHARACTER_TO_CAMPAIGN';
const REMOVE_CHARACTER_FROM_CAMPAIGN = 'REMOVE_CHARACTER_FROM_CAMPAIGN';
const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
const CLEAR_CAMPAIGNS = 'CLEAR_CAMPAIGNS';
const ENHANCE_CAMPAIGN = 'ENHANCE_CAMPAIGN';
const UPLOAD_STATE = 'UPLOAD_STATE';
const JOIN_CAMPAIGN = 'JOIN_CAMPAIGN';
const START_CAMPAIGN = 'START_CAMPAIGN';

// Define the initial state of the store
const initialState = {
    campaigns: [],
    campaign: {},
    progress: 0,
    prompt: {},
    type: 'photo',
};

export const campaignsReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPLOAD_STATE: {
        return {
          ...state,
          progress: [...state.payload, action.payload],
        };
      }
      case SET_CAMPAIGN_TYPE: {
        return {
          ...state,
          type: [...state.payload, action.payload],
        };
      }
      case START_CAMPAIGN: {
        return {
          ...state,
          campaign: action.payload,
        };
      }
      case CREATE_CAMPAIGN: {
        return {
          ...state,
          campaign: action.payload,
          campaigns: [...state.campaigns, action.payload],
        };
      }
      case REMOVE_CHARACTER_FROM_CAMPAIGN: {
        return {
          ...state,
          campaign: action.payload,
        };
      }
      case ADD_CHARACTER_TO_CAMPAIGN: {
        return {
          ...state,
          campaign: action.payload,
        };
      }
      case JOIN_CAMPAIGN: {
        return {
          ...state,
          campaign: action.payload,
        }
      }
      case READ_SINGLE_CAMPAIGN: {
        return {
          ...state,
          campaign: action.payload,
        };
      }
      case ENHANCE_CAMPAIGN: {
        return {
          ...state,
          prompt: action.payload,
        };
      }
      case READ_CAMPAIGNS: {
        return {
          ...state,
          campaigns: action.payload,
        };
      }
      case UPDATE_CAMPAIGN: {
        const { id, updates } = action.payload;
        const updatedCampaigns = state.campaigns.map((campaign) => {
          if (campaign.id === id) {
            return { ...campaign, ...updates };
          }
          return campaign;
        });
        return {
          ...state,
          campaigns: updatedCampaigns,
        };
      }
      case CLEAR_CAMPAIGNS: {
        return {
          ...state,
          campaigns: [],
          campaign: {},
        };
      }
      case DELETE_CAMPAIGN: {
        const id = action.payload;
        const remainingCampaigns = state.campaigns.filter(
          (campaign) => campaign._id !== id
        );
        return {
          ...state,
          campaigns: remainingCampaigns,
        };
      }
      default: {
        return state;
      }
    }
  }