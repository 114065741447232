import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// Reducers
import { promptsReducer } from './reducers/prompts';
import { usersReducer } from './reducers/users';
import { messagesReducer } from './reducers/messages';
import { notificationsReducer } from './reducers/notifications';
import { scenesReducer } from './reducers/scenes';
import { campaignsReducer } from './reducers/campaigns';
import { charactersReducer } from './reducers/characters';

const persistConfig = {
  key: 'root',
  storage,
};

//Allows redux make asynchronous requests
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
  campaigns: campaignsReducer,
  characters: charactersReducer,
  users: usersReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  scenes: scenesReducer,
  prompts: promptsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);

export const persistor = persistStore(store);