const READ_SINGLE_SCENE = 'READ_SINGLE_SCENE';
const READ_SCENES = 'READ_SCENES';
const CREATE_SCENE = 'CREATE_SCENE';

// Define the initial state of the store
const initialState = {
  scene: {}
};

export const scenesReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_SINGLE_SCENE: {
      return {
        ...state,
        scene: action.payload,
      };
    }
    case READ_SCENES: {
      return {
        ...state,
        scenes: action.payload,
      };
    }
    case CREATE_SCENE: {
      return {
        ...state,
        scenes: [...state.scenes, action.payload],
      };
    }
    default: {
      return state;
    }
  }
}