import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { useAuthState } from '../providers/Firebase';
import List from './Campaigns/List';

const Dashboard = () => {
    const { user } = useAuthState() //isAuthenticated

    return (
      <Container>
        <Row className="pt-4">
          <Col>
            <h3>Welcome {user?.email}</h3>
            <List/>
          </Col>
        </Row>
        
      </Container>
    )
  }

export default Dashboard