const READ_SINGLE_PROMPT  = 'READ_SINGLE_PROMPT';
const READ_PROMPTS        = 'READ_PROMPTS';
const CREATE_PROMPT       = 'CREATE_PROMPT';

// Define the initial state of the store
const initialState = {
    prompt: {}
};

export const promptsReducer = (state = initialState, action) => {
    switch (action.type) {
      case READ_SINGLE_PROMPT: {
        return {
          ...state,
          prompt: action.payload,
        };
      }
      case READ_PROMPTS: {
        return {
          ...state,
          prompts: action.payload,
        };
      }
      case CREATE_PROMPT: {
        return {
          ...state,
          prompts: [...state.prompts, action.payload],
        };
      }
      default: {
        return state;
      }
    }
  }