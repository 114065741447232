const READ_SINGLE_USER = 'READ_SINGLE_USER';
const LOGOUT = 'LOGOUT';

// Define the initial state of the store
const initialState = {
    user: {}
};

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
      case READ_SINGLE_USER: {
        return {
          ...state,
          user: action.payload,
        };
      }
      case LOGOUT: {
        return {
          ...state,
          user: {},
        };
      }
      default: {
        return state;
      }
    }
  }