import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { useAuthState } from '../../providers/Firebase';

const Choose = () => {
    const { user } = useAuthState() //isAuthenticated

    return (
      <Container>
        <Row className="pt-4">
          <Col>
            <h3>Welcome {user?.email}</h3>
          </Col>
        </Row>
        
      </Container>
    )
  }

export default Choose