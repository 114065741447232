import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import { Button } from 'react-bootstrap'
import * as Icons from 'react-bootstrap-icons'
import Image from 'react-bootstrap/Image'

const EmptyState = (props) => {
    return (
    <Col className="p-5">
        <Row>
            <Col md={{span: 6, offset: 3}} className="text-center">
                {props.image && (
                    <Image className="mb-3" src={props.image ? props.image : `/img/image-to-ai.jpg`} fluid />
                )}
                <br/><br/>
                    <h1>{props.title ? props.title : 'Get Started with your first campaign'}</h1>
                    <p>{props.subtitle ? props.subtitle : 'You can get started right away, but to submit the project for processing, you\'ll have to buy a project credit. To do this, please follow the link above.'}</p>
                <br/>
                    <div><Button as={Link} to={props.link ? props.link : '/campaigns/create'} size="lg">{props.linkText ? props.linkText : 'Create a Campaign'}</Button></div>
            </Col>
        </Row>
    </Col>
    );
}

export default EmptyState;